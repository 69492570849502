
@tailwind base;
@tailwind components;
@tailwind utilities;

body::-webkit-scrollbar {
  display: none;
}
.example::-webkit-scrollbar {
  display: none;
}

.example {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
} 
#root::-webkit-scrollbar {
  display: none;
}

#root {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
} 

.active{
  background-color: rgb(196, 193, 193);
}

.glass {
  background: rgba(255, 255, 255, 0);
  position: absolute;
  z-index: 20;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0 0px 40px rgba(8, 7, 16, 0.6);
}

::-moz-selection {
  color: white;
  background: rgb(159, 159, 157);
}

::selection {
  color: white;
  background: rgb(159, 159, 157);
}

/* Keyframes for screen sizes below or equal to 79 pixels */
@keyframes expandWidth1 {
  0% {
    width: 0;
  }
  30% {
    width: 48px;
  }
  75% {
    width: 48px;
  }
  100% {
    width: 0;
  }
}

@keyframes expandWidth2 {
  0% {
    width: 0;
  }
  30% {
    width: 30px;
  }
  75% {
    width: 30px;
  }
  100% {
    width: 0;
  }
}

@media (min-width: 768px) {
  @keyframes expandWidth1 {
    0% {
      width: 0;
    }
    30% {
      width: 59px;
    }
    75% {
      width: 59px;
    }
    100% {
      width: 0;
    }
  }

  @keyframes expandWidth2 {
    0% {
      width: 0;
    }
    30% {
      width: 40px;
    }
    75% {
      width: 40px;
    }
    100% {
      width: 0;
    }
  }
}

.animated-div1,
.animated-div2 {
  font-family: 'Courier New', Courier, monospace;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-delay: 0.5s;
  overflow: hidden;
}

.animated-div1 {
  animation-name: expandWidth1;
}

.animated-div2 {
  animation-name: expandWidth2;
}
